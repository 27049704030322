import React from 'react'
import wolfplus from "../../../image/wolf_plus.jpg"
import wolfplus1 from "../../../image/wolf_plus1.jpg"
import wolfplus2 from "../../../image/wolf_plus2.jpg"
import wolfplus3 from "../../../image/wolf_plus3.jpg"

const Wolfplus = () => {
  return (
    <div className="w-full">
      <div>
        <img className='w-full' src={wolfplus} alt="wolfplus" />
      </div>
      <div className="w-full flex flex-col mt-10 mb-20">
      <div className="flex items-center justify-center">
        <h1 className="text-center mb-10 font-bold text-3xl hover:border-b-2 border-red">Wolf plus</h1>
        </div>
        <div className="flex flex-wrap items-center justify-around ">
          <div className="w-[250px] md:w-3/12 h-48 shadow-2xl flex items-center justify-center rounded-xl shadow-lightblack m-3 md:m-0 ">
        <img className="w-full h-48 rounded-xl"  src={wolfplus1} alt="wolfplus" />
        </div>
        <div className="w-[250px] md:w-3/12 h-48 shadow-2xl flex items-center justify-center rounded-xl shadow-lightblack m-3 md:m-0 ">
        <img className="w-full h-48 rounded-xl" src={wolfplus2} alt="wolfplus" />
        </div>
        <div className="w-[250px] md:w-3/12 h-48 shadow-2xl flex items-center justify-center rounded-xl shadow-lightblack m-3 md:m-0 ">
        <img className="w-full h-48 rounded-xl" src={wolfplus3} alt="wolfplus" />
        </div>
        </div>
      </div>

     
    </div>
  )
}

export default Wolfplus