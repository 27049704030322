import g_digital from "../../image/gdig.jpg";
// import refrigerator1 from "../../image/fr1.jpg";
import joyBike from "../../image/joy 1.jpg";
// import LED2 from "../../image/LED TV.png";

const Banners = [
  {
    id: 1,
    image: joyBike,
  },
  {
    id: 2,
    image: g_digital,
  },
  // {
  //   id: 3,
  //   image: refrigerator1,
  // },
  // {
  //   id: 4,
  //   image: LED2,
  // },
  
];

export default Banners;
