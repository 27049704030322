import React from "react";
import conterBg from "../../image/counterbg1.jpeg";
import CounterCart from "./CounterCart";

const CounterDetails = () => {
  return (
    <div
      className="w-full mb-10 flex flex-wrap items-center justify-around h-full opacity-80"
      style={{
        backgroundImage: `url(${conterBg})`,
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    >
      <CounterCart number={6} title={"Years of Journey"} />
      <CounterCart number={30000} title={"Satisfied Customer"} />
      <CounterCart number={20} title={"Awards & Rewards"} />
      <CounterCart number={1200} title={"Channel Partners"} />
    </div>
  );
};

export default CounterDetails;
