import React from "react";
import message_bg from "../../image/cta-bg1.jpg";
import { Link } from "react-router-dom";

const Message = () => {
  return (
    <div
      className="w-full  sm:h-80  flex items-center justify-center p-5 sm:p-[50px]"
      style={{
        backgroundImage: `url(${message_bg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="w-full  sm:h-52 bg-white flex flex-col md:flex-row  items-center justify-around p-5 sm:p-10 ">
        <h1 className="font-bold text-xl sm:text-4xl leading-2 capitalize">
          apply for dealership
        </h1>
        <Link to="/contact">
          <button className="border-2 border-black text-black hover:bg-black hover:text-white pl-10 pr-10 p-2  rounded m-4 sm:m-0">
            Apply
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Message;
