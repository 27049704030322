import React from "react";
import "./Home.css";
import Banners from "./Banners";
import JoyEBikes from "../JoyEBikes/Joy_Ebikes.js";
import CounterDetails from "../CounterDetails/counterDetails";
import SliderImg from "../SliderImg.js";

const Home = () => {
  return (
    <div className="">
      <div className="mt-4">
        <SliderImg>
          {Banners.map((img, index) => {
            return <img src={img.image} alt="" />;
          })}
        </SliderImg>
      </div>
      <div className="mt-4">
        <SliderImg>
          {JoyEBikes.map((items, index) => {
            return (
              <div key={items.id}>
                <img className="" src={items.Joy_EBikes} alt="" />
              </div>
            );
          })}
        </SliderImg>
      </div>

      <div className="w-full flex items-center justify-center">
        <div className="w-full sm:w-2/3 bg-gray h-60 md:h-96 mt-10 mb-10 rounded shadow-2xl m-5 ">
          <iframe
           className="w-full h-full rounded"
            src="https://www.youtube.com/embed/y4wcRNA5_kg?si=P0WTHdZxqMJGp2eb"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div>
        <CounterDetails />
      </div>
    </div>
  );
};

export default Home;
