import React from "react";
import img from "../../image/contact-bg.png";
import Card from "./Card/Card";

const Contact = () => {
  
  return (
    <div className="w-full min-h-screen" id='contact'>
      <div
        className="w-full min-h-screen lg:h-screen  flex  flex-col items-center  pt-[5rem]"
        style={{ backgroundImage: `url(${img})`, backgroundPosition: "center" }}
      >
        <h1 className="text-center text-xl  font-bold uppercase font-roboto mb-5">
          Contact Us
        </h1>
        <h1 className="font-bold text-xl sm:text-4xl text-center  lg:w-[50rem] font-roboto leading-tight pl-5 pr-5 ">
          GET A FREE CONSULTATION CALL FROM OUR EXPERT TEAM
        </h1>
        <div className="">
          <Card />
        </div>
      </div>
      <div className="w-full mt-[100px] ">
        <div className="shadow-2xl rounded-xl m-[1rem] sm:m-[4rem]  flex  items-center lg:flex-row justify-between sm:pt-20  pl-5 pr-5 pb-10">
          <div className="sm:pr-3 h-3/6 w-full ">
            <div>
            <iframe
                className="rounded-xl w-full h-[350px] sm:h-[450px] mt-10 lg:mt-0"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                src="https://maps.google.com/maps?width=420&amp;height=400&amp;hl=en&amp;q=%20Besides%20NCC%20Apartments,%20Survey%20No.%20185/1,%20Lake%20Side%20Road,%20Outer%20Ring%20Rd,%20Mahadevapura,%20Bengaluru,%20Karnataka%20560048+(garuda%20mart%20india%20pvt%20ltd)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                title="map"
              >
                <a href="https://www.maps.ie/distance-area-calculator.html">
                  measure area map
                </a>
              </iframe>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default Contact;
