import React from 'react'
import globBike from "../../../image/bike-glob.jpg"
import glob1 from "../../../image/glob1.png"

const Glob = () => {
  return (
    <div className="w-full">
      <div>
        <img className="w-full " src={globBike} alt="glob" />
      </div>
      <div className="w-full flex flex-col mt-10 mb-20">
      <div className="flex items-center justify-center">
        <h1 className="text-center mb-10 font-bold text-3xl hover:border-b-2 border-red">Glob</h1>
        </div>
        <div className="flex flex-wrap items-center justify-around ">
          <div className="w-[250px] md:w-3/12 h-60 shadow-2xl flex items-center justify-center rounded-xl shadow-black m-3 md:m-0 ">
            <img className="w-full h-60 rounded-xl" src={glob1} alt="glob" />
          </div>
          <div className="w-[250px] md:w-3/12 h-60 shadow-2xl flex items-center justify-center rounded-xl shadow-black m-3 md:m-0 ">
            <img className="w-full h-60 rounded-xl" src={glob1} alt="glob" />
          </div>
          <div className="w-[250px] md:w-3/12 h-60 shadow-2xl flex items-center justify-center rounded-xl shadow-black m-3 md:m-0 ">
            <img className="w-full h-60 rounded-xl" src={glob1} alt="glob" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Glob