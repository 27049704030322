import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/Home/Home';
import Navbar from './components/Navbar/Navbar';
import Pages from "./components/HomeAppliance/Pages";
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Message from './components/message/Message';
import Ebikes from './components/E_bikes/E_bikes';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsCondition from './components/TermsCondition';
import Mihos from './components/Bikes/Mihos/Mihos';
import Wolf from './components/Bikes/Wolf/Wolf';
import Nanu from './components/Bikes/Nanu/Nanu';
import Glob from './components/Bikes/Glob/Glob';
import Wolfplus from './components/Bikes/Wolfplus/Wolfplus';
import ScrollToTop from './ScrollToTop';
import About from "./components/About/About.js"

function App() {
  return (
    <div className="App">
      <Navbar />
      <ScrollToTop>
      <Routes>
        <Route path='/' element={<Home />} /> 
        <Route path='/appliances' element={<Pages />} /> 
        <Route path='/ebikes' element={<Ebikes />} /> 
        <Route path='/contact' element={<Contact />} />
        <Route path='/about-us' element={<About />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-conditions' element={<TermsCondition />} />
        <Route path='/mihos' element={<Mihos />} />
        <Route path='/wolfplus' element={<Wolfplus />} />
        <Route path='/nanu' element={<Nanu />} />
        <Route path='/glob' element={<Glob />} />
        <Route path='/wolf' element={<Wolf />} />
      </Routes>
      </ScrollToTop>
      <Message />
      <Footer />
    </div>
  );
}

export default App;
