import React from "react";
import { Link } from "react-router-dom";
import contact_icon1 from "../../../image/contact-icon-1.webp";
import contact_icon2 from "../../../image/contact-icon-2.webp";
import contact_icon3 from "../../../image/contact-icon-3.webp";
import facebook from "../../../image/facebook.png";
import twitter from "../../../image/twitter.png";
import linkedin from "../../../image/linkedin.png";
import instagram from "../../../image/instagram.png";

const Card = () => {
  return (
    <div className="flex flex-wrap items-center justify-center  sm:space-x-5  mt-20 h-full w-full p-4 sm:p-0">
      <div className="mt-4 w-full sm:w-48 md:w-60 lg:w-80 h-72 lg:h-80 rounded-xl shadow-2xl flex flex-col items-center justify-center bg-white">
        <img className="w-20 h-20" src={contact_icon1} alt="" />
        <div className="flex flex-col items-center mt-7 ">
          <Link>
            <button className="font-medium text-sm lg:text-xl mt-3 font-roboto hover:text-red hover:border-red  border-b-[1px] border-black">
              Contact
            </button>
          </Link>

          <button className="font-medium text-sm lg:text-xl mt-5 font-roboto hover:text-red">
            admin@garudamartindia.com
          </button>
          <h1 className="mt-3 font-bold text-sm lg:text-xl font-roboto hover:text-red ">
            1800-12398-6666
          </h1>
        </div>
      </div>
      <div className="mt-4 w-full sm:w-48 md:w-60 lg:w-80 h-72 lg:h-80 rounded-xl shadow-2xl flex flex-col items-center justify-center bg-white">
        <img className="w-10" src={contact_icon3} alt="" />
        <div className="flex flex-col items-center mt-3">
          <Link>
            <button className="font-medium text-sm lg:text-xl mt-2 font-roboto hover:text-red hover:border-red  border-b-[1px] border-black">
              Location
            </button>
          </Link>
          <button className="font-medium text-sm lg:text-lg mt-3 font-roboto hover:text-red p-2">
            Survey No. 183/1, Lake Side Road, Behind NCC Apartment, Mahadevapura
            Bangalore(KA). 560048
          </button>
        </div>
      </div>
      <div className="mt-4 w-full sm:w-48 md:w-60 lg:w-80 h-72 lg:h-80 rounded-xl shadow-2xl flex flex-col items-center justify-center bg-white">
        <img className="w-20 h-20" src={contact_icon2} alt="" />
        <div className="flex flex-col items-center mt-7">
          <Link>
            <button className="font-medium text-sm lg:text-xl mt-2 font-roboto hover:text-red hover:border-red  border-b-[1px] border-black">
              Social media
            </button>
          </Link>
          <div className="flex items-center justify-left mt-7 space-x-4">
            <a
              href="https://www.facebook.com/GarudamartindiapvtLtd"
              className="w-7 md:w-10 h-7 md:h-10 rounded-full bg-white hover:bg-black hover:border-2 shadow-xl shadow-stone-500 cursor-pointer flex items-center justify-center"
            >
              <img className="w-3 md:w-5" src={facebook} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/garudamart2017/"
              className="w-7 md:w-10 h-7 md:h-10 rounded-full bg-white hover:bg-black hover:border-2 shadow-xl shadow-stone-500 cursor-pointer flex items-center justify-center"
            >
              <img className="w-3 md:w-5" src={instagram} alt="twitter" />
            </a>
            <a
              href="https://twitter.com/GarudaMartIndia"
              className="w-7 md:w-10 h-7 md:h-10 rounded-full bg-white hover:bg-black hover:border-2 shadow-xl shadow-stone-500 cursor-pointer flex items-center justify-center"
            >
              <img className="w-3 md:w-5" src={twitter} alt="twitter" />
            </a>
            <a
              href="https://www.linkedin.com/company/garuda-mart-india-private-limited/"
              className="w-7 md:w-10 h-7 md:h-10 rounded-full bg-white hover:bg-black hover:border-2 shadow-xl shadow-stone-500 cursor-pointer flex items-center justify-center"
            >
              <img className="w-3 md:w-5" src={linkedin} alt="linkedin" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
