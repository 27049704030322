import Wolf_banner from "../../image/wolf-banner.jpg"
import Wolf_era from "../../image/wolf_era.jpg"
import slide1 from "../../image/slider2.png"
import smart_bms1 from "../../image/smart-bms2.jpg"
import smart_bms2 from "../../image/smart-bms3.jpg"
import bikeImg from "../../image/bike-img.jpg"
import charging from "../../image/charging.jpg"
import efficiency1 from "../../image/efficiency.jpg"
import efficiency2 from "../../image/efficiency1.jpg"
import Joy_bike from "../../image/joy-bike.jpeg"



const EBikes = [
    {
        id:1,
        ebike: Joy_bike
    },
    {
        id: 2,
        ebike: Wolf_banner
    },
    {
        id: 3,
        ebike: Wolf_era
    }, {
        id: 4,
        ebike: slide1
    }, {
        id: 5,
        ebike: smart_bms1
    }, {
        id: 6,
        ebike: smart_bms2
    }, {
        id: 7,
        ebike: bikeImg
    }, {
        id: 8,
        ebike: charging
    }, {
        id: 9,
        ebike: efficiency1
    },
    {
        id: 10,
        ebike: efficiency2
    },
]

export default EBikes