import React from "react";
import {Link } from "react-router-dom"
import Ebikes from "./E_bikesObj.js";

const E_bikes = () => {
  return (
    <div className="w-full flex flex-wrap items-center justify-center mt-10 mb-10" id='ebikes'>
      {Ebikes.map((items, inedx) => {
        return (
          <div key={items.id} className="w-full sm:w-6/12 md:w-4/12 lg:w-5/12 m-2 shadow rounded-b-xl">
            <img
              className="w-full h-64 lg:h-96 rounded-t-xl"
              src={items.ebike}
              alt="ebikes"
            />
            <Link to="/contact">
                <button className="font-semibold  w-full rounded-b-xl  capitalize p-2 hover:bg-black hover:text-white">apply for dealership</button>
            </Link >
          </div>
        );
      })}
    </div>
  );
};

export default E_bikes;
