import banner1 from "../../image/Banner 1.png"
import banner2 from "../../image/Banner 2.png"
import banner3 from "../../image/Banner 3.png"
import banner4 from "../../image/Banner 4.png"


const Joy_EBikes = [
    {
        id: 1,
        Joy_EBikes: banner1
    },
    {
        id: 2,
        Joy_EBikes: banner2
    },
    {
        id: 3,
        Joy_EBikes: banner3
    },
    {
        id: 4,
        Joy_EBikes: banner4
    },
   
]


export default Joy_EBikes