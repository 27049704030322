import React from "react";
import Aboutimage from "../../image/About-image.png";

const About = () => {
  return (
    <div className="pl-3 md:pl-10 pr-3 md:pr-10 pt-10 pb-10 font-roboto flex items-center justify-center flex-col">
      <div className="mt-3 space-y-3 w-full md:w-5/6">
        <h1 className="font-semibold text-lg">About Us</h1>
        <p className="font-normal text-base">
          Garuda Mart was established in{" "}
          <span className="font-medium text-base">2017</span> under the
          visionary leadership of
          <span className="ml-1 font-medium">Mr. R Venkataramana</span>, and has
          since rapidly evolved into one of Bangalore's foremost and finest
          manufacturers of consumer goods. While the singular focus has been on
          the creation of consumer durables of superior performance, the mission
          is to take the excellence of ‘Make in India’ beyond the borders of the
          Indian market into international territories.
        </p>
        <p className="font-normal text-base">
          Since our inception, our unwavering ambition has been to enrich the
          lives of our end consumers, steering our journey with the adoption of
          cutting-edge technologies. Today, we stand as a dynamic and
          progressively expanding brand, propelled by a relentless commitment to
          innovation.
        </p>
        <p className="font-normal text-base">
          At the helm of our enterprise, the formidable leadership team not only
          propels the company forward and steers our strategies, but also keeps
          our workforce grounded in the lofty vision of our founders toward
          resounding success. Powered by pioneering technology and fueled by
          continuous growth, we are well on our way to achieve our aspiration to
          metamorphose into a smart and globally recognized brand.
        </p>
      </div>
      <div className="mt-5 md:w-5/6">
        <h1 className="font-semibold text-lg">Our Approach</h1>
        <div className="space-y-3 mt-3">
          <p className="font-normal text-base">
            At Garuda Mart, we are goal-driven, but also firmly believe in the
            means that justify the ends. Thus, our purpose-driven enterprise
            builds resolute teams that help us champion sustainable living and
            elevate the standards of life (not mere standards of living), all
            the while wholeheartedly, contributing to the "Make In India"
            initiative.
          </p>
          <p className="font-normal text-base">
            That is how we ensure the delivery of electronic products
            distinguished by unparalleled performance and durability designed to
            delight our clientele.
          </p>
        </div>
      </div>

      <div className="mt-5 md:w-5/6">
        <h1 className="font-semibold text-lg">Powering Our Growth</h1>
        <div className="space-y-3 mt-3 ">
          <p className="font-normal text-base">
            We strategically leverage the potent synergy of the "3 Ps" – our
            adept <span className="font-medium"> Personnel </span>, cutting-edge{" "}
            <span className="font-medium">Products</span>, and strategic{" "}
            <span className="font-medium">Partners</span> – to power our growth
            and create an enduring impact for our customers.
          </p>
        </div>
      </div>

      <div className="md:w-5/6 mt-5">
        <h1 className="font-semibold text-lg">Our Sales Journey</h1>
        <h1 className="font-semibold text-lg mt-1">
          How We Have Unleashed Our Sales Superpowers?{" "}
        </h1>
        <div className="space-y-3 mt-3 ">
          <p className="font-normal text-base">
            Our successful sales strategy operates on the fundamental principle
            of crafting products of immense utility to customers and perpetually
            innovating to increase their usefulness.
          </p>
          <p className="font-normal text-base">
            Our capability to extensively research, ideate and transform our
            products compliment our overall endeavours. With this inherent
            excellence, our products organically market themselves while carving
            their own niche.
          </p>
          <p className="font-normal text-base">
            Since our launch in 2017, we have focused on the entire customer
            sales journey – from building awareness to after sales delight.`
          </p>
        </div>
        <img className="md:w-2/6 mt-5" src={Aboutimage} alt="" />
      </div>

      <div className="mt-5 md:w-5/6">
        <h1 className="font-medium  text-lg">
          It is the entire cycle of the customer relationship with our company
          which helps us to:
        </h1>
        <ul className="list-disc ml-10 mt-3">
          <li className="font-normal text-base">
            Identify & optimise moments of truth in the Customer Experience.
          </li>
          <li className="font-normal text-base">
            Shift to a customer-focused perspective.
          </li>
          <li className="font-normal text-base">
            Assign ownership of various customer touchpoints & increase employee
            accountability.
          </li>
        </ul>
      </div>

      <div className="md:w-5/6 space-y-3">
        <h1 className="font-semibold mt-5">Core Team</h1>
        <p className="font-normal text-base mt-2">
          At the helm of{" "}
          <span className="font-medium">Garuda Mart India Pvt Ltd</span> is a
          seasoned and dynamic core team led by{" "}
          <span className="font-medium">Mr. Venkataramana Revuru</span>, the
          Chairman and Managing Director (CMD) and{" "}
          <span className="font-medium">
            Mrs. Kalavathi, the Chief Executive Officer (CEO). Mr. Venkataramana
            and Mrs. Kalavathi
          </span>
          , with their history of accomplishments and strategic vision, guides
          the company with a focus on production, operations, and marketing,
          ensuring a holistic approach to business development.
        </p>
        <p className="font-normal text-base">
          Our core team has dedicated professionals across R&D, Production and
          Marketing. This is a formidable team that brings together a wealth of
          experience and expertise, marked by a commitment to excellence in
          every facet of their roles.
        </p>
        <p className="font-normal text-base">
          While Technical Operations are spearheaded by our Chief Technical
          Officer -{" "}
          <span className="font-medium">
            Mr. Harikrishna. Mr. Babaiah - Director
          </span>
          , heads the Social Service Activities, Team Handling & Conflict
          Management. They both bring decades of diverse experience and are
          committed to delivering high quality output.{" "}
        </p>
        <p className="font-normal text-base">
          Garuda Mart is ushered by experienced professionals that are dedicated
          to making a difference to the lives of our customers, employees,
          stakeholders and our society. We foster learning & adaptation of new
          technology and are proud of our work culture that creates
          accountability, customer focus and business excellence.
        </p>
      </div>

      <div className="md:w-5/6 mt-5">
       <div className="space-y-3">
       <h1 className="font-semibold text-lg">Awards</h1>
        <p>
          Over the past seven years, Garuda Mart’s journey has been studded with
          superlatives and adorned with accolades. We have won prestigious
          awards that honour our commitment to business excellence,
          achievements, startup success, research and development, manufacturing
          prowess, and visionary leadership.
        </p>

        <p>
          In 2023, we were recognised as the top 30 business leaders of the
          year. Every award represents a testament to our unwavering dedication
          and a celebration of milestones achieved. Welcome to view our showcase
          of triumphs.
        </p>
       </div>
       <div className="flex space-x-3 mt-5">
        {/* <img className="w-1/6" src={require("../../image/About-image.png")} alt="" /> */}
        {/* <img className="w-1/6" src={require("../../image/About-image.png")} alt="" /> */}
       </div>
      </div>

      <div className="md:w-5/6 mt-5 space-y-3">
        <h1 className="font-semibold">A Word From The Chairman</h1>
        <p className="font-medium text-base">
          Dear Esteemed Partners, Stakeholders, and Visionaries of Tomorrow,
        </p>
        <p className="font-normal text-base">
          Standing at the edge of boundless possibilities, I am humbled and
          thrilled at the same time to convey the sustainable growth trajectory
          that Garuda Group is embarking upon. Fueled by strong commitment,
          relentless perseverance, and a collective vision for wholesome
          success,{" "}
          <span className="font-medium">Garuda Mart India Pvt Ltd</span> has had
          a remarkable journey so far.
        </p>
        <p>
          When it comes to excellence, we always aim for stars, and that is why
          we have set ourselves an ambitious goal to achieve a turnover of{" "}
          <span className="font-medium">INR 200 Crores by 2025</span> with
          complete faith in the capabilities of our team. Sharing our honourable
          Prime Minister’s vision of Atmanirbhar Bharat (self-dependent India),{" "}
          <span className="font-medium">
            we are keen to sculpt the future of our country, while setting an
            example and benchmark for the rest of the world.
          </span>{" "}
          Our commitment however, is not limited to topline and bottomline
          numbers, but we go beyond that. We wish to expand our Garuda family to
          double its current strength, fostering a culture of growth while
          empowering each individual to grow individually also in this success
          story.
        </p>
        <p className="font-normal text-base">
         <span className="font-medium"> The world views the Garuda Group as a conglomerate. We view ourselves
          as a nursery of passion, innovation, resilience, and execution.</span>
          Drawing confidence and strength from decades of business experience,
          we have built from scratch, our diverse portfolio spanning retail,
          manufacturing, trading & export, logistics, automobile, and food &
          beverages. That is what positions us as a trailblazer, a leader
          redefining industry norms and expectations.
        </p>
        <p>What propels us forward is not just financial success but a commitment to social responsibility that resonates globally. We mirror and build on the best practices worldwide, underscoring our duty to the planet, society, and the communities we serve.</p>
        <p>As we soar towards new horizons, let this message reverberate as an invitation to dreamers, doers, and believers. Join us in building India’s future where innovation knows no bounds, resilience overcomes all challenges, and success has a positive impact at every touch point.</p>
        <p>To the world, we declare our ambitions boldly, and with an unwavering spirit, we say: The best is yet to come.</p>
        <div className="mt-2 space-y-3">
          <h1 className="font-medium text-lg">With Gratitude and Ambition,</h1>
          <p className="font-medium text-base">[Mr. Venkataramana]</p>
          <p className="font-medium ">Chairman & Managing Director (CMD), Garuda Group</p>
        </div>
      </div>
    </div>
  );
};

export default About;
