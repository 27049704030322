import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className="p-10 font-roboto">
      {/* Privacy Policy */}
      <div>
        <h1 className="font-semibold text-xl text-center">Privacy Policy</h1>
        <div className="w-full h-[2px] bg-black mt-5 mb-5"></div>
        <p className="font-normal text-base mt-4 ">
         <Link to="/" className="text-blue italic"> Garudamartindia.com </Link> and its affiliates respect your privacy. This
          Privacy Policy provides succinctly the manner your data is collected
          and used by Garudamartindia.com. You are advised to please read the
          Privacy Policy carefully. By accessing the services provided by
          Garudamartindia .com you agree to the collection and use of your data
          by Garudamartindia .com in the manner provided in this Privacy Policy.
        </p>
      </div>

      {/* WE WANT YOU TO */}
      <div className="mt-5">
        <h1 className="font-semibold text-sm ">WE WANT YOU TO</h1>
        <div className="mt-3">
          <li>
            Feel comfortable using our desktop websites, mobile sites and mobile
            applications.
          </li>
          <li>Feel secure submitting information to us.</li>
          <li>
            Contact us with your questions or concerns about privacy on this
            site.
          </li>
          <li>
            Know that by using our sites/interface you are consenting to the
            collection of certain data.
          </li>
        </div>
      </div>

      <div className="mt-5">
        <h1 className="font-semibold text-sm ">
          WHAT INFORMATION IS, OR MAY BE, COLLECTED FROM YOU?
        </h1>
        <div className="mt-3">
          <p>
            We will automatically receive and collect certain anonymous
            information in standard usage logs through our Web server, including
            computer-identification information obtained from “cookies,” sent to
            your browser from a web server cookie stored on your hard drive an
            IP address, assigned to the computer which you use the domain server
            through which you access our service the type of computer you’re
            using the type of web browser you’re using
          </p>
        </div>
      </div>

      <div className="mt-5">
        <h1 className="font-semibold text-sm ">
          WE MAY COLLECT THE FOLLOWING PERSONALLY IDENTIFIABLE INFORMATION ABOUT
          YOU AND NOT LIMITED TO ON OUR WEBSITE AND MOBILE SITES AND MOBILE
          APPLICATIONS :
        </h1>
        <div className="mt-3">
          <li>Name including first and last name.</li>
          <li>Primary email address.</li>
          <li>Alternate email address.</li>
          <li>Mobile phone number and contact details.</li>
          <li>ZIP/Postal code.</li>
          <li>Opinions of features on our websites.</li>
          <li>Other information as per our registration process.</li>
        </div>
      </div>

      <div className="mt-5">
        <h1 className="font-semibold text-sm ">
          WE MAY ALSO COLLECT THE FOLLOWING INFORMATION:
        </h1>
        <div className="mt-3">
          <li>About the pages you visit/access.</li>
          <li>The links you click on our site.</li>
          <li>The number of times you access the page.</li>
          <li>
            The number of times you have shopped on our website, mobile site, or
            mobile applications (apps).
          </li>
          <li>
            You can terminate your account at any time. However, your
            information may remain stored in archive on our servers even after
            the deletion or the termination of your account.
          </li>
        </div>
      </div>

      <div className="mt-5">
        <h1 className="font-semibold text-sm ">
          WHO COLLECTS THE INFORMATION?
        </h1>
        <div className="mt-3">
          <p>
            We will collect anonymous traffic information from you when you
            visit our site/applications (apps). We will collect personally
            identifiable information about you only as part of a voluntary
            registration process, on-line survey, or contest or any combination
            thereof. Our advertisers may collect anonymous traffic information
            from their own assigned cookies to your browser. The
            sites/applications contain links to other Web sites/applications. We
            are not responsible for the privacy practices of such
            Websites/applications which we do not own, manage or control.
          </p>
        </div>
      </div>

      <div className="mt-5">
        <h1 className="font-semibold text-sm ">
          WE USE YOUR PERSONAL INFORMATION TO:
        </h1>
        <div className="mt-3">
          <li>Help us provide personalized features</li>
          <li>Tailor our sites, applications to your interest</li>
          <li>To get in touch with you when necessary</li>
          <li>To provide the services requested by you</li>
          <li>
            To preserve social history as governed by existing law or policy
          </li>
        </div>
      </div>

      <div className="mt-5">
        <h1 className="font-semibold text-sm ">
          WE USE CONTACT INFORMATION INTERNALLY TO:
        </h1>
        <div className="mt-3">
          <li>Direct our efforts for product improvement</li>
          <li>Contact you as a survey respondent</li>
          <li>Notify you if you win any contest; and</li>
          <li>
            Send you promotional materials from our contest sponsors or
            advertisers.
          </li>
        </div>
      </div>

      <div className="mt-5">
        <h1 className="font-semibold text-sm ">
          GENERALLY, WE USE ANONYMOUS TRAFFIC INFORMATION TO:
        </h1>
        <div className="mt-3">
          <li>
            Remind us of who you are in order to deliver to you a better and
            more personalized service from product offering, advertising and
            editorial perspectives;
          </li>
          <li>
            Recognize your access privileges to our Websites, mobile sites and/
            or Applications
          </li>
          <li>
            Track your entries in some of our promotions, sweepstakes and
            contests to indicate a player’s progress through the promotion and
            to track entries, submissions, and status in prize drawings
          </li>
          <li>Make sure that you don’t see the same ad repeatedly</li>
          <li>Help diagnose problems with our server</li>
          <li>
            Administer our websites/applications, track your session so that we
            can understand better how people use our sites/apps/ interfaces.
          </li>
        </div>
      </div>

      <div className="mt-5">
        <h1 className="font-semibold text-sm ">
          WITH WHOM WILL YOUR INFORMATION BE SHARED?
        </h1>
        <div className="mt-3">
          <li>
            We will not use your financial information for any purpose other
            than to complete a transaction with you.
          </li>
          <li>
            We do not rent, sell or share your personal information and we will
            not disclose any of your personally identifiable information to
            third parties unless:
          </li>
          <li>We have your permission</li>
          <li>To provide products or services you’ve requested</li>
          <li>
            To help investigate, prevent or take action regarding unlawful and
            illegal activities, suspected fraud, potential threat to the safety
            or security of any person, violations of Gonoise.com’s terms of use
            or to defend against legal claims
          </li>
          <li>
            Special circumstances such as compliance with subpoenas, court
            orders, requests/order, notices from legal authorities or law
            enforcement agencies requiring such disclosure.
          </li>
          <li>
            We share your information with advertisers on an aggregate basis
            only.
          </li>
        </div>
      </div>

      <div className="mt-5">
        <h1 className="font-semibold text-sm ">
          WHAT CHOICES ARE AVAILABLE TO YOU REGARDING COLLECTION, USE AND
          DISTRIBUTION OF YOUR INFORMATION?
        </h1>
        <div className="mt-3">
          <p>
            You may change your interests at any time and may opt-in or opt-out
            of any marketing / promotional / newsletters mailings. Gonoise.com
            reserves the right to send you certain service related
            communication, considered to be a part of your Gonoise.com account
            without offering you the facility to opt-out. You may update your
            information and change your account settings at any time.
          </p>
          <p className="mt-3">
            Upon request, we will remove/block your personally identifiable
            information from our database, thereby cancelling your registration.
            However, your information may remain stored in archive on our
            servers even after the deletion or the termination of your account.
          </p>
          <p className="mt-3">
            If we plan to use your personally identifiable information for any
            commercial purposes, we will notify you at the time we collect that
            information and allow you to opt-out of having your information used
            for those purposes.
          </p>
        </div>
      </div>

      <div className="mt-5">
        <h1 className="font-semibold text-sm ">
          WHAT SECURITY PROCEDURES ARE IN PLACE TO PROTECT INFORMATION FROM
          LOSS, MISUSE OR ALTERATION?
        </h1>
        <div className="mt-3">
          <p>
            To protect against the loss, misuse and alteration of the
            information under our control, we have in place appropriate
            physical, electronic and managerial procedures. For example, our
            servers are accessible only to authorized personnel and that your
            information is shared with respective personnel on need to know
            basis to complete the transaction and to provide the services
            requested by you.
          </p>
          <p className="mt-3">
            Although we will endeavor to safeguard the confidentiality of your
            personally identifiable information, transmissions made by means of
            the Internet or telecom or other network/s cannot be made absolutely
            secure. By using Gonoise.com, you agree that we will have no
            liability for disclosure of your information due to errors in
            transmission or unauthorized acts of third parties.
          </p>
        </div>
      </div>

      <div className="mt-5">
        <h1 className="font-semibold text-sm ">POLICY UPDATES</h1>
        <div className="mt-3">
          <p>
            We reserve the right to change or update this policy at any time by
            placing a prominent notice on our site. Such changes shall be
            effective immediately upon posting to this site.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
