import React, { useState } from "react";
import { NavLink ,Link} from "react-router-dom";
import logo from "../../image/logo-garudmart.png";
import styles from "./Navbar.css";
import { FcMenu } from "react-icons/fc";
import { AiOutlineClose } from "react-icons/ai";
import SideNavbar from "../SideNavbar/SideNavbar";
// import { Link } from "react-scroll";
  
const Navbar = () => {
  const [hamberger, setHamberger] = useState(false);
  return (
    <div className="navbar flex items-center justify-between  bg-saltpan h-20 p-10 sticky top-0 z-10 shadow-lg font-roboto">
      <div className="navbar-start">
        <NavLink
          to="/"
          className="logo flex items-center normal-case text-xl border-none"
        >
          <img className="w-7 sm:w-10 h-7 sm:h-10" src={logo} alt="" />
          <h1 className="font-medium ml-1 font-roboto text-base sm:text-xl">
            Garuda Mart India
          </h1>
        </NavLink>
      </div>
      <div></div>
      <div className="navbar-end  flex flex-col lg:block hidden lg:flex font-roboto opacity-75">
        <ul className="menu menu-horizontal px-1 font-medium text-base">
          <li
            className={({ isActive }) => (isActive ? `${styles.active}` : "")}
          >
            <NavLink to="/">Home</NavLink>
          </li>

          <li className="capitalize">
            <NavLink to="/about-us">About</NavLink>
          </li>

          <li className="capitalize">
            <NavLink to="/appliances">home appliances</NavLink>
          </li>

          <li className=" flex justify-between items-center">
            {/* <NavLink to="/ebikes">EV Mobility</NavLink> */}
            <NavLink to="/ebikes" className="dropdown dropdown-hover">
              <label tabIndex={0} className="flex items-center justify-center hover:text-red">
                EV Mobility
                <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="22" height="15" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>              </label>
              <ul
                tabIndex={0}
                className="dropdown-content menu top-12 left-0 p-2 shadow bg-white rounded-box w-[200px]"
              >
                <li>
                  <Link to="/mihos">Mihos</Link>
                </li>
                <li>
                  <Link to="/wolfplus">Wolf+</Link>
                </li>

                <li>
                  <Link to="/nanu">Gen Next Nanu +</Link>
                </li>

                <li>
                  <Link to="/glob">Glob</Link>
                </li>
                <li>
                  <Link to="/wolf">Wolf</Link>
                </li>
              </ul>
            </NavLink>
          </li>
          <li
            className={({ isActive }) => (isActive ? `${styles.active}` : "")}
          >
            <NavLink to="/contact">Contact Us</NavLink>
          </li>
        </ul>
      </div>

      <div
        className=" absolute right-10 block lg:hidden ml-3 sm:ml-5 cursor-pointer "
        onClick={() => setHamberger(!hamberger)}
      >
        {hamberger ? <AiOutlineClose size={25} /> : <FcMenu size={25} />}
      </div>
      <div className="">
        {hamberger ? (
          <SideNavbar setHamberger={setHamberger} hamberger={hamberger} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Navbar;
