import React from "react";
import wolfBanner from "../../../image/wolf-banner.jpg"
import wolf1 from "../../../image/wolf1.png"
import wolf2 from "../../../image/wolf2.jpg"

const Wolf = () => {
  return (
    <div className="w-full">
      <div>
        <img className="w-full " src={wolfBanner} alt="wolf" />
      </div>
      <div className="w-full flex flex-col mt-10 mb-20">
      <div className="flex items-center justify-center">
        <h1 className="text-center mb-10 font-bold text-3xl hover:border-b-2 border-red">Wolf</h1>
        </div>
        <div className="flex flex-wrap items-center justify-around ">
          <div className="w-[250px] md:w-3/12 h-48 shadow-2xl flex items-center justify-center rounded-xl shadow-lightblack m-3 md:m-0 ">
            <img className="w-full h-48 " src={wolf1} alt="wolf" />
          </div>
          <div className="w-[250px] md:w-3/12 h-48 shadow-2xl flex items-center justify-center rounded-xl shadow-lightblack m-3 md:m-0 ">
            <img className="w-full h-48 rounded-xl " src={wolf2} alt="" />
          </div>
          <div className="w-[250px] md:w-3/12 h-48 shadow-2xl flex items-center justify-center rounded-xl shadow-lightblack m-3 md:m-0 ">
            <img className="w-full h-48 " src={wolf1} alt="wolf" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wolf;
