import React from 'react'
import gennext_nanu from "../../../image/gennext_nanu.jpg"
import gennext_nanu1 from "../../../image/gennext_nanu1.jpg"
import gennext_nanu2 from "../../../image/gennext_nanu2.jpg"
import gennext_nanu3 from "../../../image/gennext_nanu3.jpg"


const Nanu = () => {
  return (
    <div className="w-full">
      <div>
        <img className="w-full " src={gennext_nanu} alt="nanu" />
      </div>
      <div className="w-full flex flex-col mt-10 mb-20">
      <div className="flex items-center justify-center">
        <h1 className="text-center mb-10 font-bold text-3xl hover:border-b-2 border-red">Nanu plus</h1>
        </div>
        <div className="flex flex-wrap items-center justify-around ">
          <div className="w-[250px] md:w-3/12 h-60 shadow-2xl flex items-center justify-center rounded-xl shadow-black m-3 md:m-0 ">
            <img className="w-full h-60 rounded-xl" src={gennext_nanu1} alt="nanu" />
          </div>
          <div className="w-[250px] md:w-3/12 h-60 shadow-2xl flex items-center justify-center rounded-xl shadow-black m-3 md:m-0 ">
            <img className="w-full h-60 rounded-xl" src={gennext_nanu2} alt="" />
          </div>
          <div className="w-[250px] md:w-3/12 h-60 shadow-2xl flex items-center justify-center rounded-xl shadow-black m-3 md:m-0 ">
            <img className="w-full h-60 rounded-xl" src={gennext_nanu3} alt="nanu" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Nanu