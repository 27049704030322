import React from "react";
import logo from "../../image/logo-garudmart.png";
import facebook from "../../image/facebook.png";
import twitter from "../../image/twitter.png";
import linkedin from "../../image/linkedin.png";
// import youtube from "../../image/youtube.png";
import instagram from "../../image/instagram.png";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <div className="flex flex-col md:flex-row bg-black text-white w-full  justify-center pb-10 pt-10 mt-10 sm:mt-20 font-roboto">
        <div className="flex flex-col w-full md:w-1/3 pl-5 sm:pl-20 pr-3 sm:pr-20 md:p-0">
          <div className="flex items-center">
            <img className="w-10 sm:w-15" src={logo} alt="logo" />
            <h1 className="font-bold text-base sm:text-xl ml-5">
              Garuda Mart India Pvt Ltd.
            </h1>
          </div>
          <p className="font-normal text-xs sm:text-sm mt-3 sm:mt-5 mr-5">
            Survey No. 183/1, Lake Side Road, Behind NCC Apartment, Mahadevapura
            Bangalore(KA). 560048
          </p>
          <h1 className="mt-2 mb-2 font-bold ">1800 12398 6666</h1>
          <p className="font-medium text-sm">admin@garudamartindia.com</p>

          
        </div>
        <div className="ml-0 md:ml-5 lg:ml-10 mt-3 flex flex-wrap justify-between pr-10 sm:justify-around w-full md:w-1/2 p-5 sm:p-20 md:p-0">
          <div>
            <h1 className="font-semibold text-sm sm:text-lg">Company</h1>
            <div className="link mt-3 sm:mt-5 flex flex-col ">
              <li>
                <Link to="/" className="font-medium text-xs sm:text-sm">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about-us" className="font-medium text-xs sm:text-sm">
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/appliances"
                  className="font-medium text-xs sm:text-sm"
                >
                  Home Appliances
                </Link>
              </li>
              <li>
                <Link to="/ebikes" className="font-medium text-xs sm:text-sm">
                  EV Mobility
                </Link>
              </li>
              <li>
                <Link to="/contact" className="font-medium text-xs sm:text-sm">
                  Contact Us
                </Link>
              </li>
            </div>
          </div>
          <div>
            <h1 className="font-semibold text-sm sm:text-lg  sm:mt-0 capitalize ">
              Useful Links
            </h1>
            <div className="link mt-5 flex flex-col ">
              <li>
                <Link to="/privacy-policy"
                  className="font-medium text-xs sm:text-sm"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms-conditions" className="font-medium text-xs sm:text-sm">
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link to="#" className="font-medium text-xs sm:text-sm">
                  Disclaimer
                </Link>
              </li>
              <li>
                <Link to="#" className="font-medium text-xs sm:text-sm">
                  Returns & Refunds
                </Link>
              </li>
            </div>
            
          </div>
        </div>
        <div className="flex flex-row md:flex-col items-center justify-center mt-5 ">
            <a
              href="https://www.facebook.com/GarudamartindiapvtLtd"
              className="w-10 h-10 m-2 rounded-full bg-white hover:bg-black hover:border-2 shadow-xl shadow-stone-500 cursor-pointer flex items-center justify-center"
            >
              <img className="w-5" src={facebook} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/garudamart2017/"
              className="w-10 h-10 m-2 rounded-full bg-white hover:bg-black hover:border-2 shadow-xl shadow-stone-500 cursor-pointer flex items-center justify-center"
            >
              <img className="w-5" src={instagram} alt="twitter" />
            </a>
            <a
              href="https://twitter.com/GarudaMartIndia"
              className="w-10 h-10 m-2 rounded-full bg-white hover:bg-black hover:border-2 shadow-xl shadow-stone-500 cursor-pointer flex items-center justify-center"
            >
              <img className="w-5" src={twitter} alt="twitter" />
            </a>
            <a
              href="https://www.linkedin.com/company/garuda-mart-india-private-limited/"
              className="w-10 h-10 m-2 rounded-full bg-white hover:bg-black hover:border-2 shadow-xl shadow-stone-500 cursor-pointer flex items-center justify-center"
            >
              <img className="w-5" src={linkedin} alt="linkedin" />
            </a>
          </div>
      </div>
      <div className="w-full h-[1px] opacity-20 bg-black mb-10 "></div>
      <div className="w-full  flex items-center justify-center pb-10">
        <h1 className="text-sm sm:text-base">Copyright</h1>
        <p className="ml-2 mr-2">©</p>
        <p className="text-red text-sm sm:text-base ">
          Garudamart India Pvt Ltd.
        </p>
      </div>
    </>
  );
};

export default Footer;
