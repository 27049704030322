import React from "react";
import Joy_banner from "../../../image/joy-banner.webp";
import Joy_Bike_black from "../../../image/Joy_black1.png"
import Joy_Bike_white from "../../../image/Joy_white.png"
import Joy_Bike_yellow from "../../../image/Joy_yellow.png"
import "./Mihos.css"

const Mihos = () => {

  return (
    <div className="w-full">
      <div>
        <img src={Joy_banner} alt="mihos" />
      </div>
      <div className="w-full flex justify-center flex-col mt-10 mb-20">
        <div className="flex items-center justify-center">
        <h1 className="text-center mb-10 font-bold text-3xl hover:border-b-2 border-red">Mihos</h1>
        </div>
        <div className="flex flex-wrap items-center justify-around ">
          <div className="w-[250px] md:w-3/12 h-48 shadow-2xl flex items-center justify-center rounded-xl shadow-lightblack m-3 md:m-0 ">
        <img className="" src={Joy_Bike_white} alt="mihos" />
        </div>
        <div className="w-[250px] md:w-3/12 h-48 shadow-2xl flex items-center justify-center rounded-xl shadow-lightblack m-3 md:m-0 ">
        <img src={Joy_Bike_yellow} alt="mihos" />
        </div>
        <div className="w-[250px] md:w-3/12 h-48 shadow-2xl flex items-center justify-center rounded-xl shadow-lightblack m-3 md:m-0 ">
        <img src={Joy_Bike_black} alt="mihos" />
        </div>
        </div>
      </div>

    
    </div>
  );
};

export default Mihos;
