import React from "react";
import washingMachine1 from "../../image/Washing-Machine-1.png";
import washingMachine2 from "../../image/Washing-Machine-2.png";
import SemiwashingMachine1 from "../../image/Semi-Automatic-Washing-Machine-1.png";
import SemiwashingMachine2 from "../../image/Semi-Automatic-Washing-Machine-2.png";
import SemiwashingMachine3 from "../../image/Semi-Automatic-Washing-Machine-3.png";
import Tv1 from "../../image/TV-final-1.png";
import Tv2 from "../../image/TV-final-2.png";
import SliderImg from "../SliderImg";

const Pages = () => {
  return (
    <div className="p-5 sm:p-10 flex flex-col" id='appliances'>
      <div className="flex flex-col lg:flex-row items-center jsutify-center">
        <div className="w-full md:w-5/12 bg-gray p-2 rounded-xl shadow-2xl ">
          <SliderImg>
            <img src={washingMachine1} alt="washingMachine1" />
            <img src={washingMachine2} alt="washingMachine2" />
          </SliderImg>
        </div>
        <div className="pl-0 sm:pl-10 mt-10 sm:mt-0">
          <h1 className="font-bold text-2xl sm:text-4xl">
            Fully Automatic Front load Washing Machine
          </h1>
          <h2 className="font-bold text-base sm:text-xl mt-2 mb-3">
            Features Specifications:
          </h2>
          <p className="font-normal text-sm sm:text-base leading-6">
            The Inverter motor is a powerful, durable and energy efﬁcient drive
            system that provides optimum washing performance while also ensuring
            a long life for your clothes. As a mark of conﬁdence in our
            state-of-the-art motor technology, it’s backed with a 10 year
            warranty as well.
          </p>

          <div className="flex items-center mt-5">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-lg ml-2">
              Fully automatic
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-lg ml-2">
              Pause & ﬁll option
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-lg ml-2">
              Inverter model
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-lg ml-2">
              Rapid 15 min quick wash
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-lg ml-2">
              Shock proof & noise proof design
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-lg ml-2">
              Safe and Convenient
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-lg ml-2">
              Capacity: 7kg
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-lg ml-2">
              99.9% anti bacterial
            </h1>
          </div>
        </div>
      </div>
      <div className="flex flex-col  md:flex-row-reverse  items-center jsutify-center mt-20">
        <div className="w-full  md:w-5/12 bg-gray p-2 rounded-xl shadow-2xl ">
          <SliderImg>
            <img
              className="w-full "
              src={SemiwashingMachine1}
              alt="washingMachine1"
            />
            <img
              className="w-full "
              src={SemiwashingMachine2}
              alt="washingMachine2"
            />
            <img
              className="w-full "
              src={SemiwashingMachine3}
              alt="washingMachine2"
            />
          </SliderImg>
        </div>
        <div className="pr-0 sm:pr-10 mt-10 sm:mt-0">
          <h1 className="font-bold text-2xl sm:text-4xl">
            Fully Automatic Front load Washing Machine
          </h1>
          <h2 className="font-bold text-base sm:text-xl mt-2 mb-3">
            Features Specifications:
          </h2>
          <p className="font-normal text-sm sm:text-base leading-6">
            The Inverter motor is a powerful, durable and energy efﬁcient drive
            system that provides optimum washing performance while also ensuring
            a long life for your clothes. As a mark of conﬁdence in our
            state-of-the-art motor technology, it’s backed with a 10 year
            warranty as well.
          </p>

          <div className="flex items-center mt-5">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-lg ml-2">
              Fully automatic
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-lg ml-2">
              Pause & ﬁll option
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-lg ml-2">
              Inverter model
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-lg ml-2">
              Rapid 15 min quick wash
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-lg ml-2">
              Shock proof & noise proof design
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-lg ml-2">
              Safe and Convenient
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-lg ml-2">
              Capacity: 7kg
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-lg ml-2">
              99.9% anti bacterial
            </h1>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row items-center jsutify-center mt-20">
        <div className="w-full  md:w-5/12 bg-gray p-2 rounded-xl shadow-2xl ">
          <SliderImg>
            <img className="w-full " src={Tv1} alt="" />
            <img className="w-full " src={Tv2} alt="" />
          </SliderImg>
        </div>
        <div className="pl-0 sm:pl-10 mt-10 md:mt-0">
          <h1 className="font-bold text-2xl sm:text-4xl">42 Inch HD LED TV</h1>
          <h2 className="font-bold text-base sm:text-xl mt-2 mb-3">
            Features Specifications:
          </h2>
          <p className="font-normal text-sm sm:text-base leading-6">
            You can enjoy your favourite movies, TV shows and other multimedia
            content in stunning quality on this television’s high defination
            display. The Dolby technology-enabled and DIGITAL PLUS sound support
            ensure you have an immersive aural experience. The improved android
            gives you access to unlimited entertainment as well as live news
            channels.
          </p>

        <div className="flex flex-wrap justify-between mt-5">
          <div className="">
            <h1 className="font-bold text-xl">Display</h1>
          <div className="flex items-center mt-5">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-base ml-2">
              Fully automatic
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-base ml-2">
              Pause & ﬁll option
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-base ml-2">
              Inverter model
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-base ml-2">
              Rapid 15 min quick wash
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-base ml-2">
              Shock proof & noise proof design
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-base ml-2">
              Safe and Convenient
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-base ml-2">
              Capacity: 7kg
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-base ml-2">
              99.9% anti bacterial
            </h1>
          </div>
          </div>
          <div>
          <div className="mt-5 sm:mt-0">
            <h1 className="font-bold text-xl">Video</h1>
          <div className="flex items-center mt-5">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-base ml-2">
            Analog TV reception format-PAL, SECAM
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-base ml-2">
            Digital TV reception format-DVB
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-base ml-2">
            Image formats supported-JPEG
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-base ml-2">
            Video formats supported-MPEG-4
            </h1>
          </div>
          <div className="flex items-center mt-1">
            <div className="w-4 h-4 rounded-full bg-black flex items-center justify-center p-1">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <h1 className="font-semibold text-sm sm:text-base ml-2">
            With upscaling
            </h1>
          </div>
      
          </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Pages;
